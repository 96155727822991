import {
  ComponentImageGalleryCarousel,
  ComponentImageGallerySlideItem,
} from 'types/generated/contentful-types'
import ImageGalleryCarouselCard from './ImageGalleryCarouselCard'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/image-carousel.module.css'

type ImageGalleryCarouselProps = Omit<ComponentImageGalleryCarousel, '_id'>

export const ImageGalleryCarousel = ({
  title,
  imageGallerySlideItemsCollection,
}: ImageGalleryCarouselProps): JSX.Element => {
  const slides = imageGallerySlideItemsCollection?.items

  return (
    <div data-name="image-carousel-header">
      <h2 className="m-auto mb-8 text-left font-medium text-[32px]">{title}</h2>
      <ForaSwiper
        className={styles.foraSwiper}
        withNumberedPagination
        params={{
          navigation: true,
          pagination: true,
          keyboard: true,
          slidesPerView: 1.2,
          spaceBetween: 16,
          centeredSlides: false,
          breakpoints: {
            768: {
              slidesPerView: 3,
              spaceBetween: 24,
              pagination: true,
              slidesOffsetAfter: 0,
            },
          },
        }}
      >
        {slides &&
          slides
            .filter((item) => item)
            .map((slideItem, i) => {
              return (
                <div key={`Slide_${i}`}>
                  <ImageGalleryCarouselCard
                    {...(slideItem as ComponentImageGallerySlideItem)}
                  />
                </div>
              )
            })}
      </ForaSwiper>
    </div>
  )
}

export default ImageGalleryCarousel
